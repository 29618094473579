<template>
    <overlay
        class-name="login-view"
    >
        <v-row
            v-if="messagesLoaded"
            no-gutters
            align="baseline"
            class="login-view__header mb-4"
        >
            <h1>{{ t('async.user.login.title') }}&nbsp;</h1>
            <span>{{ t('async.user.login.or') }}</span>&nbsp;
            <router-link
                :to="{path: '/register'}"
            >
                {{ t('async.user.login.do_register') }}
            </router-link>
        </v-row>
        <loading-dummy-h1
            v-else
        ></loading-dummy-h1>
        <v-row no-gutters>
            <v-col
                cols="12"
                md="6"
                class="login-view__form pr-0 pr-md-4"
            >
                <v-layout
                    v-if="userContentRoute && messagesLoaded"
                    class="login-view__alert"
                >
                    <v-icon>bs:$vuetify.icons.mdiAlert</v-icon>
                    <span>{{ t('async.user.login.login_notice', {feature:t(userContentRoute.key)}) }}</span>
                </v-layout>
                <loading-dummy-select
                    v-if="userContentRoute && !messagesLoaded"
                ></loading-dummy-select>
                <v-form
                    ref="form_login"
                    v-model="validLoginForm"
                    @keyup.enter="submit"
                >
                    <v-text-field
                        v-if="messagesLoaded"
                        ref="email_input"
                        v-model="user.email"
                        :rules="emailRules"
                        :label="t('async.user.form.email.label')"
                        type="email"
                        variant="underlined"
                        color="primary"
                        required
                    ></v-text-field>
                    <loading-dummy-select
                        v-else
                    ></loading-dummy-select>
                    <v-text-field
                        v-if="messagesLoaded"
                        ref="password_input"
                        v-model="user.plain_password"
                        class="login-view__form__password"
                        :rules="plainPasswordRules"
                        :label="t('async.user.form.password.label')"
                        :type="showPassword ? 'text' : 'password'"
                        variant="underlined"
                        color="primary"
                        required
                    >
                        <template #append-inner>
                            <v-btn
                                :title="t(`async.common.button.${showPassword ? 'hide' : 'show'}`)"
                                icon
                                variant="plain"
                                @click.stop="showPassword = !showPassword"
                            >
                                <v-icon
                                    :aria-label="t(`async.common.button.${showPassword ? 'hide' : 'show'}`)"
                                >
                                    {{ showPassword ? 'bs:$vuetify.icons.mdiEye' : 'bs:$vuetify.icons.mdiEyeOff' }}
                                </v-icon>
                            </v-btn>
                        </template>
                    </v-text-field>
                    <loading-dummy-select
                        v-else
                    ></loading-dummy-select>
                    <v-checkbox
                        v-if="messagesLoaded"
                        v-model="stayloggedin"
                        :label="t('async.user.form.stayloggedin.label')"
                        color="primary"
                    />
                    <loading-dummy-custom
                        v-else
                        height="36px"
                        width="300px"
                        margin="16px 0"
                    ></loading-dummy-custom>
                    <v-btn
                        v-if="messagesLoaded"
                        class="login-view__submit-btn"
                        :disabled="!validLoginForm || loginLoading"
                        :loading="loginLoading"
                        color="primary"
                        @click="submit"
                    >
                        {{ t('async.user.login.form_submit') }}
                    </v-btn>
                    <loading-dummy-custom
                        v-else
                        height="36px"
                        width="200px"
                        margin="16px 0"
                    ></loading-dummy-custom>
                </v-form>
                <span
                    v-if="messagesLoaded"
                    class="login-view__forgot_password-link text-decoration-none text-primary"
                    tabindex="0"
                    @click.stop="forgotPasswordView = !forgotPasswordView"
                    @keyup.stop.prevent.enter="forgotPasswordView = !forgotPasswordView"
                    @keyup.stop.prevent.space="forgotPasswordView = !forgotPasswordView"
                >{{ t('async.user.forgot_password.title') }}</span>
                <loading-dummy-custom
                    v-else
                    height="24px"
                    width="250px"
                    margin="20px 0"
                ></loading-dummy-custom>
            </v-col>
            <v-col
                cols="12"
                md="6"
                class="login-view__intro pl-0 pl-md-4"
            >
                <template
                    v-if="messagesLoaded"
                >
                    <p>
                        {{ t('async.user.login.intro') }}&nbsp;
                        <a
                            v-if="!showMore"
                            class="hidden-sm-and-up"
                            @click.stop="showMore = true"
                        >{{ t('async.common.button.more') }}</a>
                    </p>
                    <transition
                        name="slide-y-transition"
                    >
                        <div
                            :key="showMore"
                            :class="{'hidden-xs': !showMore}"
                            v-html="t('async.user.teaser')"
                        ></div>
                    </transition>
                </template>
                <template
                    v-else
                >
                    <loading-dummy-p
                        count="4"
                    ></loading-dummy-p>
                </template>
            </v-col>
        </v-row>
        <simple-dialog
            v-if="messagesLoaded"
            v-model="forgotPasswordView"
            :title="relaunchReset?t('async.user.forgot_password.title_relaunch'):t('async.user.forgot_password.title')"
            :confirm="t('async.user.forgot_password.form_submit')"
            :disabled="!validForgotPasswordForm || forgotPasswordLoading"
            :loading="forgotPasswordLoading"
            @cancel="forgotPasswordView = false"
            @confirm="resetPasswordAction"
        >
            <p v-if="!relaunchReset">
                {{ t('async.user.forgot_password.text') }}
            </p>
            <p v-else>
                {{ t('async.user.forgot_password.text_relaunch') }}
            </p>
            <div
                ref="form_forgot_password"
                role="form"
            >
                <v-text-field
                    v-model="forgotPasswordEmail"
                    :rules="emailRules"
                    :label="t('async.user.form.email.label')"
                    type="email"
                    required
                    variant="underlined"
                    @keyup.stop.prevent.enter="resetPasswordAction"
                ></v-text-field>
            </div>
        </simple-dialog>
    </overlay>
</template>

<script>
import Overlay from '@/assets/js/src/components/Overlay.vue'
import {
    emailRules,
    plain_passwordRules,
} from '@/assets/js/src/util/validation'
import { userType, } from '@/assets/js/src/modules/user/_pinia/types'
import SimpleDialog from '@/assets/js/src/components/SimpleDialog.vue'
import { useTrapKeyboard, } from '@/assets/js/src/util/composables/useTrapKeyboard'
import { useOverlayRouteAction, } from '@/assets/js/src/util/composables/useOverlayRouteAction'
import LoadingDummyP from '@/assets/js/src/components/loading/LoadingDummyP'
import LoadingDummyCustom from '@/assets/js/src/components/loading/LoadingDummyCustom'
import LoadingDummyH1 from '@/assets/js/src/components/loading/LoadingDummyH1'
import LoadingDummySelect from '@/assets/js/src/components/loading/LoadingDummySelect'
import { mapActions, mapState, mapWritableState, } from 'pinia'
import { useUserStore, } from '@/assets/js/src/modules/user/_pinia/user'
import { useRouteMetaStore, } from '@/assets/js/src/pinia/routeMeta'
import { useUserActionsStore, } from '@/assets/js/src/modules/user/_pinia/userActions'
import { useAppUiStore, } from '@/assets/js/src/pinia/appUi'
import { useTranslation, } from '@/assets/js/src/util/composables/useTranslation'

export default {
    name: 'SignIn',
    components: {
        LoadingDummySelect,
        LoadingDummyH1, LoadingDummyCustom, LoadingDummyP, SimpleDialog, Overlay, },
    props: {
        messagesLoaded: {
            type: Boolean,
            default: false,
        },
    },
    setup () {
        return {
            ...useTrapKeyboard(),
            ...useOverlayRouteAction(),
            ...useTranslation(),
        }
    },
    data () {
        return {
            validLoginForm: true,
            validForgotPasswordForm: true,
            forgotPasswordView: false,
            forgotPasswordLoading: false,
            loginLoading: false,
            user: userType(),
            emailRules,
            forgotPasswordEmail: '',
            plainPasswordRules: plain_passwordRules.slice(0, 2),
            stayloggedin: true,
            relaunchReset: false,
            showMore: false,
            dirtyEmail: null,
            dirtyPwd: null,
            showPassword: false,
        }
    },
    computed: {
        ...mapWritableState(useUserStore,[
            'tmpUser',
        ]),
        ...mapState(useRouteMetaStore,[
            'params',
        ]),
        ...mapState(useAppUiStore,[
            'userContentRoute',
        ]),
    },
    watch: {
        user: {
            handler (newValue, oldValue) {
                if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
                    this.tmpUser = newValue
                }
            },
        },
        forgotPasswordEmail: {
            handler (newValue) {
                this.validForgotPasswordForm = this.messagesLoaded && !emailRules.some((rule) => {
                    let res = rule(newValue)

                    return typeof res !== 'boolean' || !res
                })
            },
        },
        tmpUser: {
            handler (newValue, oldValue) {
                if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
                    this.user = newValue
                }
            },
            immediate: true,
        },
        messagesLoaded: {
            handler (newValue, oldValue) {
                if (newValue !== oldValue && newValue) {
                    if (typeof this.$route.query.forgot_password !== 'undefined') {
                        this.$nextTick(() => {
                            this.forgotPasswordView = true
                        })
                    }
                }
            },
            immediate: !import.meta.env.SSR,
        },
        forgotPasswordView (newValue, oldValue) {
            if (newValue && !this.forgotPasswordEmail) {
                this.forgotPasswordEmail = this.user.email
            }
            this.trapKeyboard(newValue, oldValue, () => {
                this.forgotPasswordView = false
            }, false)
        },

    },
    mounted () {
        let times = 0

        const interval = setInterval(() => {
            times += 1
            if ((this.dirtyEmail && this.dirtyPwd) || times === 6) {
                clearInterval(interval)
                if ((this.dirtyEmail && this.dirtyPwd && this.$refs.email_input && this.$refs.password_input)) {
                    this.$refs.email_input.$el.querySelector('label').classList += ' v-label--active'
                    this.$refs.password_input.$el.querySelector('label').classList += ' v-label--active'
                }
            }
            try {
                this.dirtyEmail = document.querySelector('input[type="email"]:-internal-autofill-selected')
                this.dirtyPwd = document.querySelector('input[type="password"]:-internal-autofill-selected')
                // eslint-disable-next-line no-unused-vars
            } catch (e) {
                clearInterval(interval)
            }
        }, 100)
    },
    methods: {
        ...mapActions(useUserActionsStore,[
            'loginUser',
            'resetPassword',
        ]),
        async submit () {
            let { valid: isValid, } = await this.$refs.form_login.validate()
            if (!isValid) {
                return
            }

            this.loginLoading = true
            try {
                let data = await this.loginUser({
                    user: globalThis.clone(this.user),
                    stayloggedin: this.stayloggedin,
                })

                if (data) {
                    if (typeof this.$route.query.redirect_uri !== 'undefined' && this.$route.query.redirect_uri.trim() !== '') {
                        window.location.href = window.location.origin + this.$route.query.redirect_uri
                    } else if (typeof this.params !== 'undefined' && typeof this.params.redirect_uri !== 'undefined' && this.params.redirect_uri.trim() !== '') {
                        window.location.href = window.location.origin + this.params.redirect_uri
                    } else {
                        this.overlayRouteAction()
                    }
                } else if (data === false) {
                    this.relaunchReset = true
                    this.forgotPasswordView = true
                }
            } finally {
                this.loginLoading = false
            }
        },
        async resetPasswordAction () {
            this.forgotPasswordLoading = true
            try {
                await this.resetPassword(this.forgotPasswordEmail)
                this.forgotPasswordView = false
            } catch { /* empty */
            } finally {
                this.forgotPasswordLoading = false
            }
        },
    },
}
</script>

<style lang="scss">
.bs-app .login-view {
    padding-bottom: 64px;

    span, a, ul li {
        font-size: 16px;
    }

    li {
        color: map-deep-get($bs-color, black);

        @include dark {
            color: map-deep-get($bs-color, dark, black);
        }
    }

    &__form {
        order: 1;

        @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
            order: 2;
        }

        .input-group--dirty {
            color: red;
        }

        .v-checkbox, .v-text-field {
            .v-label {
                color: map-deep-get($bs-color, overlay, greyDark);

                @include dark {
                    color: map-deep-get($bs-color, dark, overlay, greyDark);
                }
            }

            .v-icon {
                font-size: 24px;
            }
        }

        &__password .v-field__append-inner {
            padding-top: 0;

            .v-btn {
                width: 36px;
                height: 36px;
                color: map-deep-get($bs-color, greyDark);

                &:focus {
                    color: map-deep-get($bs-color, bsHighlight);
                }
            }

            .v-icon {
                font-size: 24px;
            }
        }
    }

    &__intro {
        order: 2;

        @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
            order: 1;
            padding: 0 0 16px;

            a {
                color: map-deep-get($bs-color, black);

                @include dark {
                    color: map-deep-get($bs-color, dark, black);
                }

                text-decoration: underline;
            }
        }
    }

    &__submit-btn {
        margin-bottom: 32px;
        margin-left: 0;
    }

    &__forgot_password-link {
        display: block;
        cursor: pointer;
    }

    &__alert {
        justify-content: center;
        margin-bottom: 20px;
        padding: 8px;
        font-size: 16px;
        border: 1px solid map-deep-get($bs-color, bsHighlight);

        .v-icon {
            flex-shrink: 0;
            width: 20px;
            height: 20px;
            margin-right: 12px;
        }
    }

    @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
        &__header {
            flex-wrap: wrap;

            h1 {
                flex-basis: 100%;
                padding-bottom: 12px;
            }

            padding-bottom: 16px;
        }
    }
}
</style>
