import {computed,} from "vue"
import {useRoute,} from "vue-router"
import {getActivePinia,} from "pinia"
import {useMessagesLoaded, useTranslation,} from "@/assets/js/src/util/composables"
import {getActiveHead,} from "@/assets/js/src/util/composables/useActiveHead"
import {genHeadOverrides,} from "@/assets/js/src/layout/meta"

export function useSignHeadInfo () {
    const activePinia = getActivePinia()
    const head = getActiveHead()
    const currentRoute = useRoute()
    const {t,} = useTranslation()
    const {messagesLoaded,} = useMessagesLoaded([ 'user', ])

    const login = computed(() => {
        return currentRoute.params.signAction === 'login'
    })

    const title = computed (() => {
        if (!messagesLoaded.value) {
            return ''
        }

        return login.value ?
            t('async.user.login.meta_title') :
            t('async.user.register.meta_title')
    })

    const canonical = computed (() => {
        return `${activePinia.state.value.baseUrl}/${login.value ? 'login' : 'register'}`
    })

    head.push(genHeadOverrides({title, canonical,}))

    return {
        login,
    }
}
