<script setup>
import SignIn from '@/assets/js/src/modules/user/_views/SignIn.vue'
import SignUp from '@/assets/js/src/modules/user/_views/SignUp.vue'
import cookieManager from '@/assets/js/src/util/cookieManagerWrapper'
import {usePrefetchData,} from '@/assets/js/src/util/composables/usePrefetchData'
import {getActivePinia, storeToRefs,} from 'pinia'
import {useUserStore,} from '@/assets/js/src/modules/user/_pinia/user'
import {useSignHeadInfo,} from "@/assets/js/src/modules/user/_composables/useSignHeadInfo"
import {computed, onMounted, onUnmounted,} from "vue"
import {useRouter,} from "vue-router"

const {login,} = useSignHeadInfo()
const activePinia = getActivePinia()
const router = useRouter()
const {tmpUser,} = storeToRefs(useUserStore(activePinia))

let {messagesLoaded,} = usePrefetchData({
    loadAsyncMessages: [ 'user', 'common', 'menu', ],
})

const component = computed(() => {
    return login.value ? SignIn : SignUp
})

onMounted(() => {
    if (!login.value && cookieManager.get('bs-login-reminder')) {
        router.push({path: '/login',}).catch(() => {})
    }
})

onUnmounted(() => {
    tmpUser.value = {}
})
</script>

<template>
    <transition
        name="slide-x-reverse-transition"
        mode="out-in"
    >
        <component
            :is="component"
            :key="component.name"
            v-bind="{
                messagesLoaded,
            }"
        ></component>
    </transition>
</template>

<style lang="scss">
.fade-transition {
    transition: opacity .2s ease;
}

.fade-enter-from, .fade-leave-from {
    opacity: 0;
}
</style>
