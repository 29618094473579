<template>
    <overlay
        class-name="register-view"
    >
        <v-row
            v-if="messagesLoaded"
            no-gutters
            align="baseline"
            class="register-view__header mb-4"
        >
            <h1>{{ t('async.user.register.title') }}&nbsp;</h1>
            <span>{{ t('async.user.register.or') }}</span>&nbsp;
            <router-link
                :to="{path: '/login'}"
            >
                {{ t('async.user.register.do_login') }}
            </router-link>
        </v-row>
        <loading-dummy-h1
            v-else
        ></loading-dummy-h1>
        <v-row no-gutters>
            <v-col
                cols="12"
                md="6"
                class="register-view__form pr-0 pr-md-4"
            >
                <v-form
                    ref="form"
                    v-model="valid"
                    @keyup.enter="submit"
                >
                    <v-text-field
                        v-if="messagesLoaded"
                        ref="email_input"
                        v-model="user.email"
                        :rules="emailRules"
                        :label="t('async.user.form.email.label')"
                        type="email"
                        name="email-sign-up"
                        variant="underlined"
                        color="primary"
                        required
                    ></v-text-field>
                    <loading-dummy-select
                        v-else
                    ></loading-dummy-select>
                    <v-text-field
                        v-if="messagesLoaded"
                        ref="password_input"
                        v-model="user.plain_password"
                        class="register-view__form__password"
                        :rules="plain_passwordRules"
                        :label="t('async.user.form.password.label')"
                        :type="showPassword ? 'text' : 'password'"
                        name="password-sign-up"
                        autocomplete="new-password"
                        variant="underlined"
                        color="primary"
                        required
                    >
                        <template #append-inner>
                            <v-btn
                                :title="t(`async.common.button.${showPassword ? 'hide' : 'show'}`)"
                                icon
                                variant="plain"
                                @click.stop="showPassword = !showPassword"
                            >
                                <v-icon
                                    :aria-label="t(`async.common.button.${showPassword ? 'hide' : 'show'}`)"
                                >
                                    {{ showPassword ? 'bs:$vuetify.icons.mdiEye' : 'bs:$vuetify.icons.mdiEyeOff' }}
                                </v-icon>
                            </v-btn>
                        </template>
                    </v-text-field>
                    <loading-dummy-select
                        v-else
                    ></loading-dummy-select>
                    <v-checkbox
                        v-if="messagesLoaded"
                        v-model="terms"
                        :rules="termsRules"
                        color="primary"
                        required
                    >
                        <template #label>
                            {{ t('async.user.form.privacy.label.title') }}
                            <small>{{ t('async.user.form.privacy.label.text') }}</small>
                        </template>
                    </v-checkbox>
                    <loading-dummy-custom
                        v-else
                        height="36px"
                        width="300px"
                        margin="16px 0"
                    ></loading-dummy-custom>

                    <v-btn
                        v-if="messagesLoaded"
                        class="register-view__submit-btn"
                        :disabled="!valid || registerLoading"
                        :loading="registerLoading"
                        color="primary"
                        @click="submit"
                    >
                        {{ t('async.user.register.form_submit') }}
                    </v-btn>
                    <loading-dummy-custom
                        v-else
                        height="36px"
                        width="200px"
                        margin="16px 0"
                    ></loading-dummy-custom>
                </v-form>
            </v-col>
            <v-col
                cols="12"
                md="6"
                class="register-view__intro pl-0 pl-md-4"
            >
                <template
                    v-if="messagesLoaded"
                >
                    <p>
                        {{ t('async.user.register.intro') }}&nbsp;
                        <a
                            v-if="!showMore"
                            class="hidden-sm-and-up"
                            @click.stop="showMore = true"
                        >{{ t('async.common.button.more') }}</a>
                    </p>
                    <div
                        :class="{'hidden-xs': !showMore}"
                        v-html="t('async.user.teaser')"
                    ></div>
                </template>
                <template
                    v-else
                >
                    <loading-dummy-p
                        count="4"
                    ></loading-dummy-p>
                </template>
            </v-col>
        </v-row>
    </overlay>
</template>

<script>
import Overlay from '@/assets/js/src/components/Overlay.vue'
import moment from 'moment-timezone/builds/moment-timezone-with-data-10-year-range'
import {
    emailRules,
    plain_passwordRules,
    termsRules,
} from '@/assets/js/src/util/validation'
import { userType, } from '@/assets/js/src/modules/user/_pinia/types'
import cookieManager from '@/assets/js/src/util/cookieManagerWrapper'
import { mapActions, mapState, mapWritableState, } from 'pinia'
import { useLangStore, } from '@/assets/js/src/modules/lang/_pinia/lang'
import LoadingDummyH1 from '@/assets/js/src/components/loading/LoadingDummyH1'
import LoadingDummyP from '@/assets/js/src/components/loading/LoadingDummyP'
import LoadingDummyCustom from '@/assets/js/src/components/loading/LoadingDummyCustom'
import { useOverlayRouteAction, } from '@/assets/js/src/util/composables/useOverlayRouteAction'
import { useUserStore, } from '@/assets/js/src/modules/user/_pinia/user'
import { useUserOptions, } from '@/assets/js/src/modules/user/_composables/useUserOptions'
import { useUserActionsStore, } from '@/assets/js/src/modules/user/_pinia/userActions'
import LoadingDummySelect from '@/assets/js/src/components/loading/LoadingDummySelect'
import { useTranslation, } from '@/assets/js/src/util/composables/useTranslation'

export default {
    name: 'SignUp',
    components: {
        LoadingDummySelect,
        LoadingDummyCustom,
        LoadingDummyP,
        LoadingDummyH1,
        Overlay,
    },
    props: {
        messagesLoaded: {
            type: Boolean,
            default: false,
        },
    },
    setup () {
        return {
            ...useOverlayRouteAction(),
            ...useUserOptions(),
            ...useTranslation(),
        }
    },
    data () {
        return {
            valid: true,
            user: userType(),
            emailRules,
            plain_passwordRules,
            terms: false,
            termsRules,
            showMore: false,
            registerLoading: false,
            showPassword: false,
        }
    },
    computed: {
        ...mapState(useLangStore,[
            'guiLanguage',
        ]),
        ...mapWritableState(useUserStore,[
            'tmpUser',
        ]),
    },
    watch: {
        user: {
            handler (newValue, oldValue) {
                if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
                    this.tmpUser = newValue
                }
            },
        },
        tmpUser: {
            handler (newValue, oldValue) {
                if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
                    this.user = newValue
                }
            },
            immediate: true,
        },
    },
    mounted () {
        let times = 0

        const interval = setInterval(() => {
            times += 1
            if ((this.dirtyEmail && this.dirtyPwd) || times === 6) {
                clearInterval(interval)
                if ((this.dirtyEmail && this.dirtyPwd && this.$refs.email_input && this.$refs.password_input)) {
                    this.$refs.email_input.$el.querySelector('label').classList += ' v-label--active'
                    this.$refs.password_input.$el.querySelector('label').classList += ' v-label--active'
                }
            }
            try {
                this.dirtyEmail = document.querySelector('input[type="email"]:-internal-autofill-selected')
                this.dirtyPwd = document.querySelector('input[type="password"]:-internal-autofill-selected')
            } catch (e) {
                clearInterval(interval)
            }
        }, 100)

        this.registerPromoCode()
    },
    methods: {
        ...mapActions(useUserActionsStore, [
            'registerUser',
        ]),
        async submit () {
            let { valid: isValid, } = await this.$refs.form.validate()
            if (!isValid) {
                return
            }

            this.registerLoading = true
            this.user.timezone = moment.tz.guess()
            this.user.language = this.guiLanguage.id
            try {
                let data = await this.registerUser({ userData:globalThis.clone(this.user),promo:this.$route.query.promo, })
                if (data) {
                    if (typeof this.$route.query.redirect_uri !== 'undefined' && this.$route.query.redirect_uri.trim() !== '') {
                        window.location.href = window.location.origin + this.$route.query.redirect_uri
                    } else {
                        this.overlayRouteAction()
                    }
                }
            } finally {
                this.registerLoading = false
            }
        },
        registerPromoCode () {
            // Promotion Code auch in Session als cookie merken
            if(this.$route.query.promo) {
                let promos = this.getUserOption('promo')
                if(!promos.includes(this.$route.query.promo)) {
                    cookieManager.set('promo', this.$route.query.promo)
                }
            }
        },
    },
}
</script>

<style lang="scss">
.bs-app .register-view {
    padding-bottom: 64px;

    &__form {
        order: 1;

        @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
            order: 2;
        }

        .v-checkbox {
            .v-selection-control {
                align-items: flex-start;

                .v-label {
                    flex-wrap: wrap;
                }
            }

            .error--text {
                small {
                    color: map-deep-get($bs-color, form, error) !important;
                    caret-color: map-deep-get($bs-color, form, error) !important;
                }
            }
        }

        .v-checkbox, .v-text-field {
            .v-label {
                flex-basis: calc(25vw - 48px);
                hyphens: auto;

                @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
                    flex-basis: calc(100vw - 64px);
                }
            }

            .v-icon {
                font-size: 24px;
            }
        }

        &__password .v-field__append-inner {
            padding-top: 0;

            .v-btn {
                width: 36px;
                height: 36px;
                color: map-deep-get($bs-color, greyDark);

                &:focus {
                    color: map-deep-get($bs-color, bsHighlight);
                }
            }
        }
    }

    &__intro {
        order: 2;

        @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
            order: 1;
            padding: 0 0 16px;

            a {
                color: map-deep-get($bs-color, black);

                @include dark {
                    color: map-deep-get($bs-color, dark, black);
                }

                text-decoration: underline;
            }
        }
    }

    &__submit-btn {
        margin-bottom: 32px;
        margin-left: 0;
    }

    &__forgot_password-link {
        display: block;
    }

    @media (max-width: #{map-deep-get($bs-xs, breakpointValue)}px) {
        &__header {
            flex-wrap: wrap;

            h1 {
                flex-basis: 100%;
                padding-bottom: 12px;
            }

            padding-bottom: 16px;
        }
    }
}
</style>
